import React from 'react';
import "../../style/game/LineHPComponent.sass";

export default class LineHPComponent extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return(
            <div className={"MainLineHPContainer"} >
                <div className={"DynamicContainerLine"} title={"HP: " + this.props.HP} style={{height: (100-this.props.HP) + "%"}} />
                <div className={"StaticContainerLine"}  title={"HP: " + this.props.HP}/>
            </div>
        );
    }
}
