import React from 'react';
import "../../style/game/PlayerComponent.sass";

export default class PlayerComponent extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    handle_unsuccessful_attack()
    {
        this.PlayerContainer.classList.add("PlayerAttackForContainer");
        this.PlayerHandleScreen.classList.add("PlayerUnsuccessfulAttack");
        setTimeout(() => {
            this.PlayerContainer.classList.remove("PlayerAttackForContainer");
        }, 1500);
        setTimeout(() => {
            this.PlayerHandleScreen.classList.remove("PlayerUnsuccessfulAttack");
        }, 3000);
    }

    handle_attack()
    {
        this.PlayerContainer.classList.add("PlayerAttackForContainer");
        this.PlayerHandleScreen.classList.add("PlayerAttack");
        setTimeout(() => {
            this.PlayerHandleScreen.classList.remove("PlayerAttack");
            this.PlayerContainer.classList.remove("PlayerAttackForContainer");
        }, 2000);
    }

    handle_block()
    {
        this.PlayerHandleScreen.classList.add("PlayerBlock");
        setTimeout(() => {
            this.PlayerHandleScreen.classList.remove("PlayerBlock");
        }, 3000);
    }

    componentDidMount()
    {
        this.props.SendFunctionManager("player", () => {this.handle_attack()}, () => {this.handle_unsuccessful_attack()}, () => {this.handle_block()});
        this.PlayerHandleScreen = document.getElementById("PlayerHandleScreen");
        this.PlayerContainer = document.getElementById("PlayerContainer");
        let ContextPlayer = document.getElementById("PlayerScreen").getContext('2d');
        let ContextPlayerHandleScreen = this.PlayerHandleScreen.getContext('2d');
        this.paintPlayer(ContextPlayer, [200,400]);
        this.paintPlayerHandle(ContextPlayerHandleScreen, [300,400], 0);
    }

    paintPlayer(in_context, in_size_screen)
    {
        in_context.clearRect(0, 0, in_size_screen[0], in_size_screen[1]);
        in_context.drawImage(this.props.Images.Player,
            0, 0, in_size_screen[0], in_size_screen[1],
            0, 0, in_size_screen[0], in_size_screen[1]);
        if (this.props.PlayerState.armor !== null) {
            in_context.drawImage(this.props.Images[this.props.PlayerState.armor],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        }
    }

    paintPlayerHandle(in_context, in_size_screen, in_rotate)
    {
        in_context.clearRect(0, 0, in_size_screen[0], in_size_screen[1]);
        //in_context.rotate(-5*Math.PI/180);
        if (this.props.PlayerState.weapon !== null){
            in_context.drawImage(this.props.Images[this.props.PlayerState.weapon],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        }
        if (this.props.PlayerState.armor === "ArmorIron"){
            in_context.drawImage(this.props.Images["HandleIron"],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        } else if (this.props.PlayerState.armor === "ArmorTitan") {
            in_context.drawImage(this.props.Images["HandleTitan"],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        } else if (this.props.PlayerState.armor === "ArmorBone") {
            in_context.drawImage(this.props.Images["HandleBone"],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        } else {
            in_context.drawImage(this.props.Images["Handle"],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        }

    }

    render()
    {
        return(
            <div id={"PlayerContainer"} className={"MainPlayerContainer"}>
                <canvas id={"PlayerScreen"} className={"PlayerScreen"} width={200} height={400} />
                <canvas id={"PlayerHandleScreen"} className={"PlayerHandleScreen"} width={300} height={400} />
            </div>
        );
    }
}
