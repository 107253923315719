export class CryptoGenerator
{
    decipher(crypto_string)
    {
        let ResultString = "";
        let ArrayCryptoSymbols = crypto_string.match(/.{1,3}/g);
        for (let i = 0; i < ArrayCryptoSymbols.length; i++)
        {
            ResultString += this.UnCryptoDictionary[ArrayCryptoSymbols[i]];
        }
        return ResultString;
    }

    encrypt(string)
    {
        let ResultCryptoString = "";
        for (let i = 0; i < string.length; i++) {
            ResultCryptoString += this.CryptoDictionary[string[i]][Math.floor(Math.random() * 3)];
        }
        return ResultCryptoString;
    }

    constructor()
    {
        this.CryptoDictionary = {
            "a": ["aqt", "bf#", "Cq3"],
            "b": ["awg", "b$g", "4Ca"],
            "c": ["aeb", "bh^", "C5z"],
            "d": ["arr", "b^j", "Cx7"],
            "e": ["atf", "^bk", "6Cs"],
            "f": ["ayv", "b&l", "cw8"],
            "g": ["auy", "bz&", "c9e"],
            "h": ["aih", "&bx", "c0f"],
            "i": ["aon", "*Bc", "1cv"],
            "j": ["Apu", "B*v", "c3b"],
            "k": ["Aaj", "Bb*", "cg3"],
            "l": ["Asm", "%Bn", "3ct"],
            "m": ["Adx", "Bm@", "cy5"],
            "n": ["Dhq", "!j]", "Q5s"],
            "o": ["Dna", "j![", "6Qd"],
            "p": ["Dmd", "jp!", "Qf6"],
            "q": ["Djz", "(jo", "Q7g"],
            "r": ["Duv", "ji)", "7Qh"],
            "s": ["dio", "[ju", "Q8j"],
            "t": ["dkk", "jy]", "Qk8"],
            "u": ["d,j", "{jt", "q9l"],
            "v": ["d.h", "jr}", "9qv"],
            "w": ["dly", "=je", "q;0"],
            "x": ["dog", "j=w", "q0z"],
            "y": ["dpb", "jq=", "1qx"],
            "z": ["d;n", "-ja", "q2c"],
            "\"": ["f61", "gaz", "haH"],
            "[": ["f32", "gqx", "hsA"],
            "]": ["f7$", "gwc", "hdS"],
            ":": ["f2#", "gev", "hfD"],
            "{": ["f1!", "grb", "hgF"],
            "}": ["f&4", "gtn", "hhG"],
            "'": ["f**", "gym", "hjH"],
            "_": ["f$%", "gu,", "hkJ"],
            "-": ["f@#", "gi.", "hlK"],
            ",": ["R12", "R3.", "RuK"],
            ".": ["R51", "R6.", "RhK"],
              0: ["f()", "go/", "hbL"],
              1: ["f5%", "gpl", "hvP"],
              2: ["f29", "g[k", "hcO"],
              3: ["f28", "g]j", "hrI"],
              4: ["f34", "glh", "htU"],
              5: ["f4^", "g;g", "hyY"],
              6: ["f76", "gkf", "huT"],
              7: ["f88", "gjd", "hpR"],
              8: ["f99", "ghs", "hqE"],
              9: ["f00", "gga", "hzQ"]
        };

        this.UnCryptoDictionary = {
            "aqt": "a", "bf#": "a", "Cq3": "a",
            "awg": "b", "b$g": "b", "4Ca": "b",
            "aeb": "c", "bh^": "c", "C5z": "c",
            "arr": "d", "b^j": "d", "Cx7": "d",
            "atf": "e", "^bk": "e", "6Cs": "e",
            "ayv": "f", "b&l": "f", "cw8": "f",
            "auy": "g", "bz&": "g", "c9e": "g",
            "aih": "h", "&bx": "h", "c0f": "h",
            "aon": "i", "*Bc": "i", "1cv": "i",
            "Apu": "j", "B*v": "j", "c3b": "j",
            "Aaj": "k", "Bb*": "k", "cg3": "k",
            "Asm": "l", "%Bn": "l", "3ct": "l",
            "Adx": "m", "Bm@": "m", "cy5": "m",
            "Dhq": "n", "!j]": "n", "Q5s": "n",
            "Dna": "o", "j![": "o", "6Qd": "o",
            "Dmd": "p", "jp!": "p", "Qf6": "p",
            "Djz": "q", "(jo": "q", "Q7g": "q",
            "Duv": "r", "ji)": "r", "7Qh": "r",
            "dio": "s", "[ju": "s", "Q8j": "s",
            "dkk": "t", "jy]": "t", "Qk8": "t",
            "d,j": "u", "{jt": "u", "q9l": "u",
            "d.h": "v", "jr}": "v", "9qv": "v",
            "dly": "w", "=je": "w", "q;0": "w",
            "dog": "x", "j=w": "x", "q0z": "x",
            "dpb": "y", "jq=": "y", "1qx": "y",
            "d;n": "z", "-ja": "z", "q2c": "z",
            "f61": "\"", "gaz": "\"", "haH": "\"",
            "f32": "[", "gqx": "[", "hsA": "[",
            "f7$": "]", "gwc": "]", "hdS": "]",
            "f2#": ":", "gev": ":", "hfD": ":",
            "f1!": "{", "grb": "{", "hgF": "{",
            "f&4": "}", "gtn": "}", "hhG": "}",
            "f**": "'", "gym": "'", "hjH": "'",
            "f$%": "_", "gu,": "_", "hkJ": "_",
            "f@#": "-", "gi.": "-", "hlK": "-",
            "R12": ",", "R3.": ",", "RuK": ",",
            "R51": ".", "R6.": ".", "RhK": ".",
            "f()":  0 , "go/":  0 , "hbL":  0 ,
            "f5%":  1 , "gpl":  1 , "hvP":  1 ,
            "f29":  2 , "g[k":  2 , "hcO":  2 ,
            "f28":  3 , "g]j":  3 , "hrI":  3 ,
            "f34":  4 , "glh":  4 , "htU":  4 ,
            "f4^":  5 , "g;g":  5 , "hyY":  5 ,
            "f76":  6 , "gkf":  6 , "huT":  6 ,
            "f88":  7 , "gjd":  7 , "hpR":  7 ,
            "f99":  8 , "ghs":  8 , "hqE":  8 ,
            "f00":  9 , "gga":  9 , "hzQ":  9
        };
    }
}
