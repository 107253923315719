import React from 'react';
import Model from './js/OFFLINEMODEL';
import MainComponent from "./js/components/MainComponent";
import Authorization from "./js/components/Authorization"
import './js/style/MainComponent.sass'
export default class App extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      authorization: false,
      visible_login_form: false
    };

    this.Model = new Model(
        (new_value) => {this.handle_change_authorization(new_value)},
        (new_value) => {this.handle_change_visible_login_form(new_value)}
    );
  }

  componentDidMount()
  {
    this.Model.check_authorization();
  }

  handle_change_authorization(new_value)
  {
    this.setState({authorization: new_value});
  }

  handle_change_visible_login_form(new_value)
  {
    this.setState({visible_login_form: new_value});
  }

  render()
  {
    return(
      <div style={{width: "100%", height: "100%"}}>
        {this.state.visible_login_form ?
            <Authorization model = {this.Model}/>
            :
          <div style={{width: "100%", height: "100%"}}>
            {this.state.authorization === true ?
                <MainComponent
                  Model = {this.Model}
                />
                :
                <div className='waiting'>
                  Подождите, выполняется проверка авторизации
                </div>
            }
          </div>
        }
      </div>
    );
  }
}
