import React from 'react';
import "../style/GameMainComponent.sass";
import PlayerComponent from "./game/PlayerComponent";
import ComputerPlayerComponent from "./game/ComputerPlayerComponent";

import IMGPlayer from "../../sprites/player.png";
import IMGArmorIron from "../../sprites/armors/iron-armor.png";
import IMGArmorWood from "../../sprites/armors/wood-armor.png";
import IMGHandle from "../../sprites/handles/handle.png";
import IMGHandleIron from "../../sprites/handles/iron-handle.png";
import IMGWeaponIron from "../../sprites/weapon/iron-sword.png";
import IMGWeaponWood from "../../sprites/weapon/wood-sword.png";
import IMGArmorTitan from "../../sprites/armors/titan-armor.png";
import IMGArmorBone from "../../sprites/armors/bone-armor.png";
import IMGWeaponBronze from "../../sprites/weapon/bronze-sword.png";
import IMGWeaponExcalibur from "../../sprites/weapon/excalibur-sword.png";
import IMGHandleTitan from "../../sprites/handles/titan-handle.png";
import IMGHandleBone from "../../sprites/handles/bone-handle.png";

import ManagerGameComponent from "./game/ManagerGameComponent";
import NewsComponent from "./game/NewsComponent";


const ImagesURI = {
    Player: IMGPlayer,
    ArmorIron: IMGArmorIron,
    ArmorWood: IMGArmorWood,
    ArmorTitan: IMGArmorTitan,
    ArmorBone: IMGArmorBone,
    Handle: IMGHandle,
    HandleIron: IMGHandleIron,
    HandleTitan: IMGHandleTitan,
    HandleBone: IMGHandleBone,
    WeaponIron: IMGWeaponIron,
    WeaponWood: IMGWeaponWood,
    WeaponBronze: IMGWeaponBronze,
    WeaponExcalibur: IMGWeaponExcalibur
};

export default class GameMainComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.CountImagesReady = 0;
        this.CountImagesMustReady = 0;
        for (let key in ImagesURI) {
            this.CountImagesMustReady++;
        }
        this.state = {
            Images: null,
            PlayerState: {
                armor: this.props.save.armor,
                weapon: this.props.save.weapon
            },
            ComputerState: {
                armor: this.props.selectedAntagonist.armor,
                weapon: this.props.selectedAntagonist.weapon
            },
            PlayerHP: 100,
            ComputerHP: 100,
            ManagerFunctionsPlayer: null,
            ManagerFunctionsComputer: null,
            FunctionResetManager: null,
            ViewNews: null
        };
        if (this.props.oldGameHPPlayers !== null){
            this.state.PlayerHP = this.props.oldGameHPPlayers.playerHP;
            this.state.ComputerHP = this.props.oldGameHPPlayers.computerHP;
        }
        this.Images = {};
        for (let key in ImagesURI) {
            this.Images[key] = new Image();
            this.Images[key].src = ImagesURI[key];
            this.Images[key].onload = () => {
                this.CountImagesReady++;
                if (this.CountImagesReady === this.CountImagesMustReady)
                {
                    this.setState({Images: this.Images});
                    delete this.Images;
                }
            }
        }
    }

    get_manager_function_players(player,function_attack,function_unsuccessful_attack,function_block)
    {
        if (player === "player"){
            this.setState({
                ManagerFunctionsPlayer: {
                    attack: function_attack,
                    block: function_block,
                    unsuccessfulAttack: function_unsuccessful_attack
                }
            });
        } else if (player === "computer"){
            this.setState({
                ManagerFunctionsComputer: {
                    attack: function_attack,
                    block: function_block,
                    unsuccessfulAttack: function_unsuccessful_attack
                }
            });
        }
    }

    get_function_reset_manager(in_function)
    {
        this.setState({FunctionResetManager: in_function});
    }

    get_function_view_news(in_function)
    {
        this.setState({ViewNews: in_function});
    }

    handle_send_step(in_object_selection)
    {
        this.props.Model.handle_send_step(
            in_object_selection.attack,
            in_object_selection.block,
            (in_response) => {this.handle_get_step_result(in_response)}
        );
    }

    handle_get_step_result(in_response)
    {
        if(!in_response.successful_attack){
            this.state.ManagerFunctionsPlayer.unsuccessfulAttack();
            this.state.ManagerFunctionsComputer.block();
            this.state.ViewNews({
                message: "БЛОК!",
                damage: (this.state.ComputerHP - in_response.ComputerHP),
                status: false
            });
        } else {
            this.state.ManagerFunctionsPlayer.attack();
            this.state.ViewNews({
                message: "УДАР!",
                damage: (this.state.ComputerHP - in_response.ComputerHP),
                status: true
            });
        }
        setTimeout(() => {
            this.setState({ComputerHP: in_response.ComputerHP});
        }, 1000);
        setTimeout(() => {
            if(in_response.successful_block){
                this.state.ManagerFunctionsComputer.unsuccessfulAttack();
                this.state.ManagerFunctionsPlayer.block();
                this.state.ViewNews({
                    message: "БЛОК!",
                    damage: (this.state.PlayerHP - in_response.PlayerHP),
                    status: true
                });
            } else {
                this.state.ManagerFunctionsComputer.attack();
                this.state.ViewNews({
                    message: "УДАР!",
                    damage: (this.state.PlayerHP - in_response.PlayerHP),
                    status: false
                });
            }
            setTimeout(() => {
                this.setState({PlayerHP: in_response.PlayerHP});
            }, 1000);
            setTimeout(() => {
                if(in_response.end_game !== null && in_response.end_game !== undefined){
                    if (in_response.end_game === "player"){
                        this.state.ViewNews({
                            message: "ВЫ ПОБЕДИЛИ!",
                            prize: "+" + in_response.prize,
                            time: 7000,
                            status: true
                        });
                    } else if (in_response.end_game === "computer") {
                        this.state.ViewNews({
                            message: "ВЫ ПРОИГРАЛИ!",
                            prize: in_response.prize,
                            time: 7000,
                            status: false
                        });
                    }
                    setTimeout(() => {
                        this.props.change_save({money: in_response.prize});
                        this.props.reset_state();
                        this.props.change_page();
                    }, 9000);
                } else {
                    this.state.FunctionResetManager();
                }
            }, 4000);
        }, 4000);
    }

    check_allow_start_game()
    {
        if (this.state.Images !== null &&
            this.state.PlayerState !== null &&
            this.state.ComputerState !== null &&
            this.state.PlayerHP !== null &&
            this.state.ComputerHP !== null ){
                return true;
        }
        return false;
    }

    render()
    {
        return(
            <div className={"MainGameContainer"}>
                {this.check_allow_start_game() &&
                    <div className={"InnerGameContainer"}>
                        <NewsComponent
                            SendFunctionViewNews = {(in_function) => {this.get_function_view_news(in_function)}}
                        />
                        <PlayerComponent
                            Images = {this.state.Images}
                            PlayerState = {this.state.PlayerState}
                            SendFunctionManager = {(player,function_attack,function_unsuccessful_attack,function_block) => {this.get_manager_function_players(player,function_attack,function_unsuccessful_attack,function_block)}}
                        />
                        <ComputerPlayerComponent
                            Images = {this.state.Images}
                            PlayerState = {this.state.ComputerState}
                            SendFunctionManager = {(player,function_attack,function_unsuccessful_attack,function_block) => {this.get_manager_function_players(player,function_attack,function_unsuccessful_attack,function_block)}}
                        />
                        {(this.state.ManagerFunctionsPlayer !== null && this.state.ManagerFunctionsComputer !== null) &&
                            <ManagerGameComponent
                                PlayerHP = {this.state.PlayerHP}
                                ComputerHP = {this.state.ComputerHP}
                                SendStep = {(in_object_selection) => {this.handle_send_step(in_object_selection)}}
                                SendResetFunction = {(in_function) => {this.get_function_reset_manager(in_function)}}
                            />
                        }
                    </div>
                }
            </div>
        );
    }
}
