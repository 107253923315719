import React from "react";
import "../style/ModalWindow.sass";

export default class ModalWindows extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            input: ""
        };
    }

    render()
    {
        return(
            <div
                className={"ContainerModalWindow"}
            >
                <div className={"InsideContainer"}>
                    <button
                        onClick={() => {this.props.close()}}
                        className={"closeButtonModal"}
                    >
                        X
                    </button>
                    <textarea
                        className={"textAreaModal"}
                        defaultValue={this.props.output}
                        onChange={(event) => {this.setState({input: event.target.value})}}
                    />
                    {(this.props.function_button !== undefined && this.props.function_button !== null) &&
                        <button
                            className={"submitButtonModal"}
                            onClick={() => {
                                this.props.function_button(this.state.input)
                            }}
                        >
                            {this.props.title_button}
                        </button>
                    }
                </div>
            </div>
        );
    }
}
