import React from 'react';
import "../style/MagazineComponent.sass";
import MagazineComponent from "./MagazineComponent";
import SelectAntagonist from "./SelectAntagonist";
import GameMainComponent from "./GameMainComponent";
import ModalWindows from "./ModalWindow";

export default class MainComponent extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            page: "main",
            save: {
                armor: null,
                weapon: null,
                money: 0
            },
            selected_antagonist: {
                armor: null,
                weapon: null
            },
            old_game_hp_players: null,
            local_model: true,
            modal_status: false,
            modal_output: "",
            modal_title_button: "",
            modal_function_button: null
        };
    }

    componentDidMount()
    {
        this.props.Model.load_save((response) => {this.handle_get_data_save(response)});
    }

    handle_change_modal(in_object)
    {
        if (in_object.status !== undefined && in_object.status !== null){
            this.setState({modal_status: in_object.status});
        }
        if (in_object.output !== undefined && in_object.output !== null){
            this.setState({modal_output: in_object.output});
        } else {
            this.setState({modal_output: ""});
        }
        if (in_object.function !== undefined && in_object.function !== null){
            this.setState({modal_function_button: in_object.function});
        } else {
            this.setState({modal_function_button: null});
        }
        if (in_object.title_button !== undefined && in_object.title_button !== null){
            this.setState({modal_title_button: in_object.title_button});
        } else {
            this.setState({modal_title_button: ""});
        }
    }

    handle_reset_state()
    {
        this.setState({
            selected_antagonist: {
                armor: null,
                weapon: null
            },
            old_game_hp_players: null
        });
    }

    handle_get_data_save(response)
    {
        this.handle_change_save({
            armor: response.player_armor,
            weapon: response.player_weapon,
            money: response.player_money
        });
        if (response.old_game){
            this.handle_change_antagonist({
               armor: response.computer_armor,
               weapon: response.computer_weapon
            });
            this.setState({
                old_game_hp_players: {
                    playerHP: response.player_hp,
                    computerHP: response.computer_hp
                },
                page: "game"
            });
        }
    }

    handle_change_antagonist(in_object)
    {
        if(in_object.armor !== null && in_object.armor !== undefined){
            this.state.selected_antagonist.armor = in_object.armor;
        }
        if(in_object.weapon !== null && in_object.weapon !== undefined){
            this.state.selected_antagonist.weapon = in_object.weapon;
        }
        this.setState({selected_antagonist: this.state.selected_antagonist});
    }

    handle_change_save(in_object)
    {
        if(in_object.armor !== null && in_object.armor !== undefined){
            this.state.save.armor = in_object.armor;
        }
        if(in_object.weapon !== null && in_object.weapon !== undefined){
            this.state.save.weapon = in_object.weapon;
        }
        if(in_object.money !== null && in_object.money !== undefined){
            this.state.save.money += in_object.money;
            if (this.state.save.money < 0){
                this.state.save.money = 0;
            }
        }
        this.setState({save: this.state.save});
    }


    handle_change_page(new_page)
    {
        this.setState({page: new_page});
    }

    render()
    {
        return(
            <div className={"MainComponentMainContainer"}>
                {this.state.modal_status &&
                    <ModalWindows
                        status={this.state.modal_status}
                        output={this.state.modal_output}
                        title_button={this.state.modal_title_button}
                        function_button={this.state.modal_function_button}
                        close={() => {
                            this.handle_change_modal({status: false})
                        }}
                    />
                }
                {this.state.page === "main" &&
                    <div className={"ButtonsContainer"}>
                        <button
                            className={"Button greenButton"}
                            onClick={() => this.handle_change_page("magazine")}
                        >
                            магазин
                        </button>
                        <button
                            className={"Button greenButton"}
                            onClick={() => this.handle_change_page("select_antagonist")}
                        >
                            выбор противника
                        </button>
                        {this.state.local_model &&
                            <div className={"ButtonsContainerLocalModel"}>
                                <button
                                    onClick={() => {this.props.Model.export_save((in_object) => {this.handle_change_modal(in_object)})}}
                                    className={"Button yellowButton"}
                                >
                                    экспорт сохранения
                                </button>
                                <button
                                    onClick={() => {this.props.Model.import_save((in_object) => {this.handle_change_modal(in_object)})}}
                                    className={"Button yellowButton"}
                                >
                                    импорт сохранения
                                </button>
                                <button
                                    className={"Button redButton"}
                                    onClick={() => {
                                        this.handle_change_modal({
                                            status: true,
                                            output: "ВСЕ ДАННЫЕ БУДУТ УДАЛЕНЫ БЕЗВОЗВРАТНО! ВЫ УВЕРЕНЫ?",
                                            title_button: "УВЕРЕН",
                                            function: () => {this.props.Model.clear_all()}
                                        });
                                    }}
                                >
                                    УДАЛИТЬ ВСЕ ДАННЫЕ
                                </button>
                            </div>
                        }
                        <button
                            className={"Button redButton"}
                            onClick={() => this.props.Model.logout()}
                        >
                            ВЫЙТИ
                        </button>
                    </div>
                }
                {this.state.page === "magazine" &&
                    <MagazineComponent
                        Model = {this.props.Model}
                        change_page = {(new_page) => {this.handle_change_page(new_page)}}
                        change_save = {(in_object) => {this.handle_change_save(in_object)}}
                        player_money = {this.state.save.money}
                    />
                }
                {this.state.page === "select_antagonist" &&
                    <SelectAntagonist
                        Model = {this.props.Model}
                        change_page = {(new_page) => {this.handle_change_page(new_page)}}
                        change_antagonist = {(in_object) => {this.handle_change_antagonist(in_object)}}
                    />
                }
                {this.state.page === "game" &&
                    <GameMainComponent
                        save = {this.state.save}
                        selectedAntagonist = {this.state.selected_antagonist}
                        oldGameHPPlayers = {this.state.old_game_hp_players}
                        Model = {this.props.Model}
                        change_page = {() => {this.handle_change_page("main")}}
                        change_save = {(in_object) => {this.handle_change_save(in_object)}}
                        reset_state = {() => {this.handle_reset_state()}}
                    />
                }
            </div>
        );
    }
}
