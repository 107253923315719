import React from 'react';
import "../../style/game/ManagerGameComponent.sass";
import LineHPComponent from "./LineHPComponent";

export default class ManagerGameComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            current_selecting: "attack"
        };
    }

    componentDidMount()
    {
        this.props.SendResetFunction(() => {this.handle_select(null)});
    }

    handle_select(value)
    {
        switch (this.state.current_selecting)
        {
            case "attack":
                if (value !== null){
                    this.setState({
                        current_selecting: "block"
                    });
                    this.select_attack = value;
                }
            break;

            case "block":
                if (value !== null) {
                    this.setState({
                        current_selecting: null
                    });
                    let Attack = this.select_attack;
                    delete this.select_attack;
                    this.props.SendStep({
                        attack: Attack,
                        block: value,
                    });
                }
            break;

            default:
                this.setState({
                    current_selecting: "attack"
                });
            break;
        }
    }

    render()
    {
        return(
            <div className={"MainManagerGameContainer"}>
                <div className={"RowContainerLinesHP"}>
                    <LineHPComponent
                        HP = {this.props.PlayerHP}
                    />
                    <LineHPComponent
                        HP = {this.props.ComputerHP}
                    />
                </div>
                {this.state.current_selecting !== null &&
                    <div className={"RowContainerSelecting"}>
                        {this.state.current_selecting === "attack" &&
                            <h1>выбор направления <div style={{color: "red"}}>атаки</div></h1>
                        }
                        {this.state.current_selecting === "block" &&
                            <h1>выбор направления <div style={{color: "green"}}>блока</div></h1>
                        }
                        <button
                            className={"Button"}
                            onClick={() => {this.handle_select("head")}}
                        >
                            голова
                        </button>
                        <button
                            className={"Button"}
                            onClick={() => {this.handle_select("tors")}}
                        >
                            торс
                        </button>
                        <button
                            className={"Button"}
                            onClick={() => {this.handle_select("legs")}}
                        >
                            ноги
                        </button>
                    </div>
                }
            </div>
        );
    }
}
