import React from 'react';
import "../../style/game/ComputerPlayerComponent.sass";

export default class ComputerPlayerComponent extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    handle_unsuccessful_attack()
    {
        this.ComputerContainer.classList.add("ComputerAttackForContainer");
        this.ComputerHandleScreen.classList.add("ComputeUnsuccessfulAttack");
        setTimeout(() => {
            this.ComputerContainer.classList.remove("ComputerAttackForContainer");
        }, 1500);
        setTimeout(() => {
            this.ComputerHandleScreen.classList.remove("ComputeUnsuccessfulAttack");
        }, 3000);
    }

    handle_attack()
    {
        this.ComputerContainer.classList.add("ComputerAttackForContainer");
        this.ComputerHandleScreen.classList.add("ComputerAttack");
        setTimeout(() => {
            this.ComputerContainer.classList.remove("ComputerAttackForContainer");
            this.ComputerHandleScreen.classList.remove("ComputerAttack");
        }, 2000, );
    }

    handle_block()
    {
        this.ComputerHandleScreen.classList.add("ComputerBlock");
        setTimeout(() => {
            this.ComputerHandleScreen.classList.remove("ComputerBlock");
        }, 3000, );
    }

    componentDidMount()
    {
        this.props.SendFunctionManager("computer", () => {this.handle_attack()}, () => {this.handle_unsuccessful_attack()}, () => {this.handle_block()});
        this.ComputerHandleScreen = document.getElementById("ComputerHandleScreen");
        this.ComputerContainer = document.getElementById("ComputerContainer");
        let ContextPlayer = document.getElementById("ComputerScreen").getContext('2d');
        let ContextPlayerHandleScreen = this.ComputerHandleScreen.getContext('2d');
        this.paintPlayer(ContextPlayer, [200,400]);
        this.paintPlayerHandle(ContextPlayerHandleScreen, [300,400], 0);
    }

    paintPlayer(in_context, in_size_screen)
    {
        in_context.clearRect(0, 0, in_size_screen[0], in_size_screen[1]);
        in_context.drawImage(this.props.Images.Player,
            0, 0, in_size_screen[0], in_size_screen[1],
            0, 0, in_size_screen[0], in_size_screen[1]);
        if (this.props.PlayerState.armor !== null) {
            in_context.drawImage(this.props.Images[this.props.PlayerState.armor],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        }
    }

    paintPlayerHandle(in_context, in_size_screen, in_rotate)
    {
        in_context.clearRect(0, 0, in_size_screen[0], in_size_screen[1]);
        //in_context.rotate(-5*Math.PI/180);
        if (this.props.PlayerState.weapon !== null){
            in_context.drawImage(this.props.Images[this.props.PlayerState.weapon],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        }
        if (this.props.PlayerState.armor === "ArmorIron"){
            in_context.drawImage(this.props.Images["HandleIron"],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        } else if (this.props.PlayerState.armor === "ArmorTitan") {
            in_context.drawImage(this.props.Images["HandleTitan"],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        } else if (this.props.PlayerState.armor === "ArmorBone") {
            in_context.drawImage(this.props.Images["HandleBone"],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        } else {
            in_context.drawImage(this.props.Images["Handle"],
                0, 0, in_size_screen[0], in_size_screen[1],
                0, 0, in_size_screen[0], in_size_screen[1]);
        }
    }

    render()
    {
        return(
            <div id={"ComputerContainer"} className={"MainComputerContainer"}>
                <canvas id={"ComputerScreen"} className={"ComputerScreen"} width={200} height={400} />
                <canvas id={"ComputerHandleScreen"} className={"ComputerHandleScreen"} width={300} height={400} />
            </div>
        );
    }
}
