import React from 'react';
import "../style/AuthorizationComponent.sass";





export default  class Authorization extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            login:'',
            password:'',
            page_login: true
        }
    }

    handle_login(e){
        e.preventDefault();
        this.props.model.login(this.state.login,this.state.password);
    }

    handle_registration(e)
    {
        e.preventDefault();
        this.props.model.registration(
            this.state.login,
            this.state.password
        );
    }

    render(){
        return(
            <div className='form-container'>
                <div style={{display: "flex"}}>
                    <button
                        className="submitButton yellowButton"
                        onClick={() => {this.setState({page_login: !this.state.page_login})}}
                    >
                        {this.state.page_login ?
                            <div>LOGIN</div>
                            :
                            <div>REGISTRATION</div>
                        }
                    </button>
                </div>
                <form className='form'>
                    <input type="text" placeholder={"login (only latin layout, only lover case)"} className="inputText" onChange={(e)=>{this.setState({login:e.target.value})}}></input>
                    <input type="password" placeholder={"password (only latin layout, only lover case)"} className="inputText" onChange={(e)=>{this.setState({password:e.target.value})}}></input>
                    {this.state.page_login ?
                        <input
                            type="submit"
                            className="submitButton greenButton"
                            value={"войти"}
                            onClick={(event) => this.handle_login(event)}>
                        </input>
                        :
                        <input
                            type="submit"
                            className="submitButton greenButton"
                            value={"зарегистрироваться"}
                            onClick={(event) => this.handle_registration(event)}>
                        </input>
                    }
                </form>
            </div>
        )


    }

}
