import React from 'react';
import "../../style/game/NewsComponent.sass";

export default class NewsComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            message: "1234",
            color: "green",
            damage: null,
            prize: null
        }
    }

    componentDidMount()
    {
        this.NewsContainer = document.getElementById("IDNewsContainer");
        this.props.SendFunctionViewNews((in_object) => {this.view_message(in_object)});
    }

    view_message(in_object)
    {
        let Color = "red";
        let Damage = null;
        let Prize = null;
        let Time = 2000;
        if (in_object.status){
            Color = "green"
        }
        if (in_object.time !== undefined && in_object.time !== null){
            Time = in_object.time;
        }
        if (in_object.damage !== undefined){
            Damage = in_object.damage;
        }
        if (in_object.prize !== undefined){
            Prize = in_object.prize;
        }
        this.setState({
            message: in_object.message,
            color: Color,
            damage: Damage,
            prize: Prize
        });
        setTimeout(() => {
            this.NewsContainer.classList.add("view");
            setTimeout(() => {
                this.NewsContainer.classList.remove("view");
            }, Time);
        }, 1000);
    }

    render()
    {
        return(
            <div className={"MainNewsContainer"}>
                <div id={"IDNewsContainer"} className={"InnerNewsContainer"}>
                    <h1 style={{color: this.state.color}}>{this.state.message}</h1>
                    {this.state.damage !== null &&
                        <h1>урон: -{this.state.damage}</h1>
                    }
                    {this.state.prize !== null &&
                        <h1>Золото: {this.state.prize}</h1>
                    }
                </div>
            </div>
        );
    }
}
