import React from 'react';
import "../style/MainComponent.sass";

export default class MagazineComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            assortiment: []
        };
    }

    componentDidMount()
    {
        this.props.Model.get_assortment_of_shops((in_new_assortiment) => {this.handle_change_assortiment(in_new_assortiment)});
    }

    handle_change_assortiment(in_new_assortiment)
    {
        this.setState({assortiment: in_new_assortiment});
    }

    handle_buy(id, item_type, prise, service_name)
    {
        let ChangeSaveObject = {
            money: 0-prise
        };
        ChangeSaveObject[item_type] = service_name;
        this.props.Model.buy_item(
            id,
            item_type,
            () => {
                this.props.change_save(ChangeSaveObject);
                alert("Куплено успешно!");
            });
    }

    render()
    {
        return(
            <div className={"MainMagazineContainer"}>
                <div>
                    Ваше золото: {this.props.player_money}
                </div>
                <table className={"MagazineTableItems"}>
                    <caption>Магазин</caption>
                    <tr>
                        <th>Название</th>
                        <th>Стоимость</th>
                        <th></th>
                    </tr>
                    {this.state.assortiment.map(item => (
                        <tr
                            key={"Magazine_key_" + item.item_type + "_item::" + item.id}
                        >
                            <td>{item.name}</td>
                            <td>{item.prise}</td>
                            <td>
                                <button
                                    onClick={() => {this.handle_buy(item.id, item.item_type, item.prise, item.service_name)}}
                                >
                                    купить
                                </button>
                            </td>
                        </tr>
                    ))}
                </table>
                <button className={"BackButton"} onClick={() => this.props.change_page("main")}>назад</button>
            </div>
        );
    }
}
