import React from 'react';
import "../style/SelectAntagonist.sass";

export default class SelectAntagonist extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            enemies: []
        };
    }

    componentDidMount()
    {
        this.props.Model.get_assortment_antagonists((in_new_enemies) => {this.handle_change_enemy(in_new_enemies)});
    }

    handle_change_enemy(in_new_enemies)
    {
        this.setState({enemies: in_new_enemies});
    }

    handle_select(antagonist_id)
    {
        this.props.Model.select_antagonist(antagonist_id, (response) => {this.succesful_selected(response)});
    }

    succesful_selected(response)
    {
        this.props.change_antagonist({
            armor: response.armor,
            weapon: response.weapon
        });
        setTimeout(() => {this.props.change_page("game");}, 1000);
    }

    render()
    {
        return(
            <div className={"MainEnemyTableContainer"}>
                <table className={"EnemyTable"}>
                    <caption>Выбор противника</caption>
                    <tr>
                        <th>Имя</th>
                        <th>Оружие</th>
                        <th>Броня</th>
                        <th>Награда</th>
                        <th></th>
                    </tr>
                    {this.state.enemies.map(item => (
                        <tr
                            key={"Enemy_key_id::" + item.id}
                        >
                            <td>{item.name}</td>
                            <td>{item.weapon}</td>
                            <td>{item.armor}</td>
                            <td>{item.prize} золота</td>
                            <td><button onClick={() => this.handle_select(item.id)}>Выбор</button></td>
                        </tr>
                    ))}
                </table>
                <button className={"BackButton"} onClick={() => this.props.change_page("main")}>назад</button>
            </div>
        );
    }
}
