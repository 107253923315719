import {CryptoGenerator} from "./CryptoGenerator";

export default class MODEL
{
    constructor(in_change_authorization, in_visible_login_form)
    {
        this.CryptoGenerator = new CryptoGenerator();
        this.change_authorization = in_change_authorization;
        this.visible_login_form = in_visible_login_form;

        this.antagonists = [];
        this.weapons = [];
        this.armors = [];

        this.CheckAuth = false;
        this.DataLoaded = {
            must: 3,
            loaded: 0
        };

        this.areasMaySelected = ["head", "tors", "legs"];

        this.__get_http_request("/resource/antagonists.json", (in_array) => {
           this.antagonists = in_array;
            this.DataLoaded.loaded++;
            this.check_all_loaded();
        });
        this.__get_http_request("/resource/armors.json", (in_array) => {
            this.armors = in_array;
            this.DataLoaded.loaded++;
            this.check_all_loaded();
        });
        this.__get_http_request("/resource/weapons.json", (in_array) => {
            this.weapons = in_array;
            this.DataLoaded.loaded++;
            this.check_all_loaded();
        });
    }

    check_all_loaded()
    {
        if (this.DataLoaded.must === this.DataLoaded.loaded){
            if (this.CheckAuth){
                this.change_authorization(true);
                this.visible_login_form(false);
            }
        }
    }

    get_item_from_this_storage(storage, id)
    {
        let Array = [];
        switch (storage) {
            case "antagonist":
                if (id === null) {
                    return {
                        "id": null,
                        "armor_id": null,
                        "weapon_id": null,
                        "name": null,
                        "prize": null
                    };
                } else {
                    Array = this.antagonists;
                }
            break;
            case "armor":
                if (id === null) {
                    return {
                        "id": null,
                        "name": "без брони",
                        "defense": 0,
                        "service_name": null,
                        "prise": 0
                    };
                } else {
                    Array = this.armors;
                }
            break;
            case "weapon":
                if (id === null) {
                    return {
                        "id": null,
                        "name": "без оружия",
                        "damage": 10,
                        "service_name": null,
                        "prise": 0
                    };
                } else {
                    Array = this.weapons;
                }
            break;
            default:
                return null;
        }
        return Array.find(item => item.id === id);
    }

    check_authorization()
    {
        let Failed = true;
        if (localStorage["selected_user"] !== undefined && localStorage["selected_user"] !== null){
            if (localStorage["users"] !== undefined && localStorage["users"] !== null)
            {
                let Users = JSON.parse(this.CryptoGenerator.decipher(localStorage["users"]));
                let SelectedUser = this.CryptoGenerator.decipher(localStorage["selected_user"]);
                if (Users[SelectedUser] !== undefined && Users[SelectedUser] !== null){
                    Failed = false;
                    if (this.DataLoaded.must === this.DataLoaded.loaded) {
                        this.change_authorization(true);
                        this.visible_login_form(false);
                    } else {
                        this.CheckAuth = true;
                    }
                }
            }
        }
        if (Failed) {
            this.change_authorization(false);
            this.visible_login_form(true);
        }
    }

    login(login, password)
    {
        let Failed = true;
        let LoverLogin = login.toLowerCase();
        let LoverPassword = password.toLowerCase();
        if (localStorage["users"] !== undefined && localStorage["users"] !== null) {
            let Users = JSON.parse(this.CryptoGenerator.decipher(localStorage["users"]));
            if (Users[LoverLogin] !== undefined && Users[LoverLogin] !== null){
                if (Users[LoverLogin].password === LoverPassword) {
                    localStorage["selected_user"] = this.CryptoGenerator.encrypt(LoverLogin);
                    Failed = false;
                    this.change_authorization(true);
                    this.visible_login_form(false);
                }
            }
        }
        if (Failed) {
            this.change_authorization(false);
            this.visible_login_form(true);
        }
    }

    clear_all()
    {
        localStorage.removeItem("selected_user");
        localStorage.removeItem("users");
        localStorage.removeItem("saves");
        localStorage.removeItem("current_game");
        this.change_authorization(false);
        this.visible_login_form(true);
    }

    logout()
    {
        localStorage.removeItem("selected_user");
        this.change_authorization(false);
        this.visible_login_form(true);
    }

    export_save(function_manager_modal)
    {
        let Failed = true;
        if (localStorage["saves"] !== undefined && localStorage["saves"] !== null) {
            let Saves = JSON.parse(this.CryptoGenerator.decipher(localStorage["saves"]));
            let SelectedUser = this.CryptoGenerator.decipher(localStorage["selected_user"]);
            if (Saves[SelectedUser] !== undefined && Saves[SelectedUser] !== null) {
                Failed = false;
                function_manager_modal({
                    status: true,
                    output: this.CryptoGenerator.encrypt(JSON.stringify(Saves[SelectedUser])),
                    function: null
                });
            }
        }
        if (Failed) {
            alert("Ошибка!");
        }
    }

    import_save(function_manager_modal)
    {
        let Failed = true;
        if (localStorage["saves"] !== undefined && localStorage["saves"] !== null) {
            let Saves = JSON.parse(this.CryptoGenerator.decipher(localStorage["saves"]));
            let SelectedUser = this.CryptoGenerator.decipher(localStorage["selected_user"]);
            Failed = false;
            function_manager_modal({
                status: true,
                output: "",
                title_button: "ИМПОРТ",
                function: (input_string) => {
                    Saves[SelectedUser] = JSON.parse(this.CryptoGenerator.decipher(input_string));
                    localStorage["saves"] = this.CryptoGenerator.encrypt(JSON.stringify(Saves));
                    function_manager_modal({
                        status: false,
                        function: null
                    });
                }
            });
        }
        if (Failed) {
            alert("Ошибка!");
        }
    }

    registration(in_login, in_password)
    {
        let Users = {};
        let LoverLogin = in_login.toLowerCase();
        let LoverPassword = in_password.toLowerCase();
        if (localStorage["users"] !== undefined && localStorage["users"] !== null) {
            Users = JSON.parse(this.CryptoGenerator.decipher(localStorage["users"]));
        }
        if (Users[LoverLogin] === undefined || Users[LoverLogin] === null) {
            Users[LoverLogin] = {};
            Users[LoverLogin].password = LoverPassword;
            let Saves = {};
            if (localStorage["saves"] !== undefined && localStorage["saves"] !== null) {
                Saves = JSON.parse(this.CryptoGenerator.decipher(localStorage["saves"]));
            }
            Saves[LoverLogin] = {
                armor_id: null,
                weapon_id: null,
                money: 1000
            };
            localStorage["users"] = this.CryptoGenerator.encrypt(JSON.stringify(Users));
            localStorage["saves"] = this.CryptoGenerator.encrypt(JSON.stringify(Saves));
            this.login(LoverLogin, LoverPassword);
        } else {
            alert("An account with this username is already registered");
        }
    }

    load_save(CALLBACK)
    {
        let Response = {
            player_armor: null,
            player_weapon: null,
            player_money: 1000,
            old_game: false,
            player_hp: null,
            computer_hp: null,
            computer_armor: null,
            computer_weapon: null
        };
        if (localStorage["saves"] !== undefined && localStorage["saves"] !== null) {
            let Saves = JSON.parse(this.CryptoGenerator.decipher(localStorage["saves"]));
            let SelectedUser = this.CryptoGenerator.decipher(localStorage["selected_user"]);
            if (Saves[SelectedUser] !== undefined && Saves[SelectedUser] !== null) {
                let SavePlayer = Saves[SelectedUser];
                Response.player_armor = this.get_item_from_this_storage("armor", SavePlayer.armor_id)["service_name"];
                Response.player_weapon = this.get_item_from_this_storage("weapon", SavePlayer.weapon_id)["service_name"];
                Response.player_money = SavePlayer.money;
                if (localStorage["current_game"] !== undefined && localStorage["current_game"] !== null) {
                    let CurrentGame = JSON.parse(this.CryptoGenerator.decipher(localStorage["current_game"]));
                    if (CurrentGame[SelectedUser] !== undefined && CurrentGame[SelectedUser] !== null) {
                        let CurrentGamePlayer = CurrentGame[SelectedUser];
                        let Antagonist = this.get_item_from_this_storage(
                            "antagonist",
                            CurrentGamePlayer.antagonist_id
                        );
                        Response.old_game = true;
                        Response.player_hp = CurrentGamePlayer.player_hp;
                        Response.computer_hp = CurrentGamePlayer.computer_hp;
                        Response.computer_armor = this.get_item_from_this_storage(
                            "armor",
                            Antagonist.armor_id
                        )["service_name"];
                        Response.computer_weapon = this.get_item_from_this_storage(
                            "weapon",
                            Antagonist.weapon_id
                        )["service_name"];
                    }
                }
            }
        }
        CALLBACK(Response);
    }

    get_assortment_of_shops(CALLBACK)
    {
        let Result = [];
        this.weapons.map(item => {
            Result.push({
                id: item.id,
                item_type: "weapon",
                name: item.name,
                prise: item.prise,
                service_name: item.service_name
            });
            return null;
        });
        this.armors.map(item => {
            Result.push({
                id: item.id,
                item_type: "armor",
                name: item.name,
                prise: item.prise,
                service_name: item.service_name
            });
            return null;
        });
        CALLBACK(Result);
    }

    buy_item(in_item_id, in_item_type, CALLBACK)
    {
        let Failed = true;
        if (localStorage["saves"] !== undefined && localStorage["saves"] !== null) {
            let Saves = JSON.parse(this.CryptoGenerator.decipher(localStorage["saves"]));
            let SelectedUser = this.CryptoGenerator.decipher(localStorage["selected_user"]);
            if (Saves[SelectedUser] !== undefined && Saves[SelectedUser] !== null) {
                let Money = Saves[SelectedUser].money;
                if (in_item_type === "armor") {
                    Money += this.get_item_from_this_storage("armor", Saves[SelectedUser].armor_id)["prise"] / 2;
                    Money -= this.get_item_from_this_storage("armor", in_item_id)["prise"];
                    if (Money >= 0) {
                        Saves[SelectedUser].armor_id = in_item_id;
                        Saves[SelectedUser].money = Money;
                        Failed = false;
                    }
                } else if (in_item_type === "weapon") {
                    Money += this.get_item_from_this_storage("weapon", Saves[SelectedUser].weapon_id)["prise"] / 2;
                    Money -= this.get_item_from_this_storage("weapon", in_item_id)["prise"];
                    if (Money >= 0) {
                        Saves[SelectedUser].weapon_id = in_item_id;
                        Saves[SelectedUser].money = Money;
                        Failed = false;
                    }
                }
                if (Failed) {
                    alert("Недостаточно средств!");
                    Failed = false;
                } else {
                    localStorage["saves"] = this.CryptoGenerator.encrypt(JSON.stringify(Saves));
                    CALLBACK();
                }
            }
        }
        if (Failed) {
            alert("Ошибка");
        }
    }

    get_assortment_antagonists(CALLBACK)
    {
        let Result = [];
        this.antagonists.map(item => {
            let Armor = this.get_item_from_this_storage("armor", item.armor_id);
            let Weapon = this.get_item_from_this_storage("weapon", item.weapon_id);
            Result.push({
                id: item.id,
                name: item.name,
                armor: Armor.name,
                weapon: Weapon.name,
                prize: item.prize
            });
            return null;
        });
        CALLBACK(Result);
    }

    select_antagonist(in_antagonist_id, CALLBACK)
    {
        let Result = {
            armor: null,
            weapon: null
        };
        let Antagonist = this.get_item_from_this_storage("antagonist", in_antagonist_id);
        let Armor = this.get_item_from_this_storage("armor", Antagonist.armor_id);
        let Weapon = this.get_item_from_this_storage("weapon", Antagonist.weapon_id);
        Result.armor = Armor.service_name;
        Result.weapon = Weapon.service_name;
        let CurrentGame = {};
        if (localStorage["current_game"] !== undefined && localStorage["current_game"] !== null) {
            CurrentGame = JSON.parse(this.CryptoGenerator.decipher(localStorage["current_game"]));
        }
        CurrentGame[this.CryptoGenerator.decipher(localStorage["selected_user"])] = {
            player_hp: 100,
            computer_hp: 100,
            antagonist_id: in_antagonist_id
        };
        localStorage["current_game"] = this.CryptoGenerator.encrypt(JSON.stringify(CurrentGame));
        CALLBACK(Result);
    }

    handle_send_step(in_attack, in_block, CALLBACK)
    {
        let Response = {
            successful_attack: false,
            successful_block: false,
            PlayerHP: null,
            ComputerHP: null,
//            end_game: null,
//            prize: null
        };
        let CorrectAttack = false;
        let Failed = true;
        let CurrentGame = {};
        let SelectedUser = "";
        let Saves = {};
        if (localStorage["current_game"] !== undefined && localStorage["current_game"] !== null) {
            CurrentGame = JSON.parse(this.CryptoGenerator.decipher(localStorage["current_game"]));
            SelectedUser = this.CryptoGenerator.decipher(localStorage["selected_user"]);
            if (CurrentGame[SelectedUser] !== undefined && CurrentGame[SelectedUser] !== null) {
                if (localStorage["saves"] !== undefined && localStorage["saves"] !== null) {
                    Saves = JSON.parse(this.CryptoGenerator.decipher(localStorage["saves"]));
                    if (Saves[SelectedUser] !== undefined && Saves[SelectedUser] !== null) {
                        Failed = false;
                    }
                }
            }
        }
        if (!Failed) {
            if (this.areasMaySelected.find(direction => direction === in_attack) !== null) {
                CorrectAttack = true;
            }
            let ComputerAttack = this.areasMaySelected[Math.floor(Math.random() * 3)];
            let ComputerBlock = this.areasMaySelected[Math.floor(Math.random() * 3)];
            let Antagonist = this.get_item_from_this_storage("antagonist", CurrentGame[SelectedUser].antagonist_id);
            let PlayerHP = CurrentGame[SelectedUser].player_hp;
            let PlayerDamage = this.get_item_from_this_storage("weapon", Saves[SelectedUser].weapon_id)["damage"];
            let PlayerDefense = this.get_item_from_this_storage("armor", Saves[SelectedUser].armor_id)["defense"];
            let ComputerHP = CurrentGame[SelectedUser].computer_hp;
            let ComputerDamage = this.get_item_from_this_storage("weapon", Antagonist.weapon_id)["damage"];
            let ComputerDefense = this.get_item_from_this_storage("armor", Antagonist.armor_id)["defense"];

            let DealDamage = 0;
            if (in_attack === ComputerBlock || !CorrectAttack){
                Response.successful_attack = false;
                DealDamage = PlayerDamage / 2 - ComputerDefense;
            } else {
                Response.successful_attack = true;
                DealDamage = PlayerDamage - ComputerDefense;
            }
            if (DealDamage < 0){
                DealDamage = 0;
            }
            Response.ComputerHP = ComputerHP - DealDamage;
            if (in_block === ComputerAttack){
                Response.successful_block = true;
                DealDamage = ComputerDamage / 2 - PlayerDefense;
            } else {
                Response.successful_block = false;
                DealDamage = ComputerDamage - PlayerDefense;
            }
            if (DealDamage < 0){
                DealDamage = 0;
            }
            Response.PlayerHP = PlayerHP - DealDamage;
            if (Response.ComputerHP <= 0) {
                Response.end_game = "player";
                Response.prize = Antagonist.prize;
                delete CurrentGame[SelectedUser];
            } else if (Response.PlayerHP <= 0) {
                Response.end_game = "computer";
                Response.prize = -Antagonist.prize;
                delete CurrentGame[SelectedUser];
            } else {
                CurrentGame[SelectedUser].player_hp = Response.PlayerHP;
                CurrentGame[SelectedUser].computer_hp = Response.ComputerHP;
            }
            if (Response.end_game !== undefined && Response.end_game !== null) {
                if ((Saves[SelectedUser].money + Response.prize) < 0){
                    Saves[SelectedUser].money = 0;
                } else {
                    Saves[SelectedUser].money += Response.prize;
                }
                localStorage["saves"] = this.CryptoGenerator.encrypt(JSON.stringify(Saves));
            }
            localStorage["current_game"] = this.CryptoGenerator.encrypt(JSON.stringify(CurrentGame));
            CALLBACK(Response);
        } else {
            alert("Ошибка!");
        }
    }

    __get_http_request(API, CALLBACK) {
        fetch(API, {
            method: 'GET'
        })
            .then(response => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    alert("AHTUNG! ERROR!");
                }
            }).then(body => {
            if (body !== null) {
                if (CALLBACK !== null) {
                    CALLBACK(body);
                }
            }
        });
    }
}
